import React from 'react'
import PropTypes from 'prop-types'

const InfoNavbar = ({inverted}) => (
  <nav className={"navbar navbar-extra-info " + (inverted ? 'inverted' : '')}>
    <div className="container">
      <div className="navbar-start">
        <a className="navbar-item" href="/#"><span className="img-label" role="img" aria-label="telefoon">☎️</span> +32 475 407 665</a>
        <a className="navbar-item" href="/#"><span className="img-label" role="img" aria-label="telefoon">📬</span> johan@vanbulckbvba.be</a>
      </div>
    </div>
  </nav>
);
export default InfoNavbar;

InfoNavbar.propTypes = {
    inverted: PropTypes.bool.isRequired
}