import React from 'react'
import Link from 'gatsby-link'

// import PropTypes from 'prop-types'
import logoTVB from '../img/tvb-logo.png'
import tomatenFooter from '../../static/img/tomaten_footer.png'

const TVBFooter = () => (
  <footer className="tvb-footer" style={{height: 475, background: 'url('+ tomatenFooter +') center bottom no-repeat #FBF9F6'}}>
      <div className="container has-text-centered">
          <div style={{display: 'flex', flexDirection: 'column'}}>
              <img alt="Logo Tuinbouwbedrijf Van Bulck" src={logoTVB} width="260"/>
              <Link to="/contact" className="btn-contact">Contact</Link>
              <div className="contactgegevens" style={{fontSize: '15px', color: 'black', fontWeight: 200, marginBottom: '65px'}}>
                <p>+32 475 407 665</p>
                <p>Steenbeek 1 2580 Putte</p>
                <p>Tuinbouwbedrijf Van Bulck BVBA</p>
                <p>BE 0440.692.477</p>
                <p><a href="./pdf/privacyverklaring.pdf">Privacyverklaring</a></p>
              </div>
              
              <p>© 2018 by SG</p>
          </div>
      </div>
  </footer>
);
export default TVBFooter;

// TVBFooter.propTypes = {
//     title: PropTypes.string.isRequired,
//     subtitle: PropTypes.string,
//     background: PropTypes.string.isRequired,
// }