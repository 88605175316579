import React from 'react'
import Helmet from 'react-helmet'

import Navbar from '../components/Navbar'
import TVBFooter from '../components/TVBFooter'
import InfoNavbar from '../components/InfoNavbar'
import logo from '../img/tvb-logo.png'
import './all.sass'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet title="Tuinbouwbedrijf Van Bulck">
      <link rel="canonical" href="https://www.tuinbouwbedrijfvanbulck.be/" />
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta name="Description" content="Tuinbouwbedrijf Van Bulck, een passie voor tomaten."></meta>
      <meta property="og:locale" content="be_NL"></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content="Tuinbouwbedrijf Van Bulck"></meta>
      <meta property="og:url" content="https://www.tuinbouwbedrijfvanbulck.be/"></meta>
      <meta property="og:site_name" content="Tuinbouwbedrijf Van Bulck"></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:title" content="Tuinbouwbedrijf Van Bulck"></meta>
      <link rel="icon" type="image/png" href={logo} />
      <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="eaaf50cc-48ba-4077-96fb-b08f35769581" data-blockingmode="auto" type="text/javascript"></script>
    </Helmet>
    <script id="CookieDeclaration" src="https://consent.cookiebot.com/eaaf50cc-48ba-4077-96fb-b08f35769581/cd.js" type="text/javascript"></script>
    <InfoNavbar />
    <Navbar />
    <div>
      {children}
    </div>
    <TVBFooter />
  </div>
)

export default TemplateWrapper