import React from 'react';
import Link from 'gatsby-link';

// import Headroom from 'headroom.js';

import logoTVB from '../img/tvb-logo.png';

export default class Navbar extends React.Component {
  isOpen = false;

  componentDidMount() {
    try {
      this.Headroom = require("headroom.js");
      if (typeof document !== "undefined") {
        const myElement = document.getElementById('main-nav');
        // construct an instance of Headroom, passing the element
        const headroom = new this.Headroom(myElement, {
          offset: 180,
          tolerance: 20
        });
        // initialise
        headroom.init();
      }
    } catch (e) {
      console.error(e);
    }
  }

  toggleNav() {
    const nav = typeof document !== "undefined" ? document.getElementById('myNav') : null;
    if (this.isOpen && nav !== null) {
      nav.style.width = '0%';
      this.isOpen = false;
      document.getElementsByTagName('body')[0].classList.remove('body-scroll')
    } else {
      nav.style.width = '100%';
      this.isOpen = true;
      document.getElementsByTagName('body')[0].classList.add('body-scroll')
    }
  }
  render() {
    return (
      <nav className="navbar is-transparent" id="main-nav">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img
                src={logoTVB}
                alt="Logo Tuinbouwbedrijf Van Bulck"
                width="183"
                style={{ maxHeight: '100%' }}
              />
            </Link>
            <div
              className="navbar-burger burger"
              data-target="navbarExampleTransparentExample"
              onClick={() => this.toggleNav()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div id="navbarExampleTransparentExample" className="navbar-menu">
            <div className="navbar-start" />
            <div className="navbar-end">
              <Link className="navbar-item" to="/">
                Home
              </Link>
              <Link className="navbar-item" to="/over-ons">
                Over ons
              </Link>
              <Link className="navbar-item" to="/onze-aanpak">
                Onze aanpak
              </Link>
              <Link className="navbar-item" to="/fotos">
                Foto's
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div id="myNav" className="overlay">
          <a
            style={{ position: 'absolute', right: '15px' }}
            href="/#"
            className="closebtn"
            onClick={() => this.toggleNav()}
          >
            &times;
          </a>
          <div className="overlay-content">
            <Link to="/" onClick={() => this.toggleNav()}>
              Home
            </Link>
            <Link to="/over-ons" onClick={() => this.toggleNav()}>
              Over ons
            </Link>
            <Link to="/onze-aanpak" onClick={() => this.toggleNav()}>
              Onze aanpak
            </Link>
            <Link to="/fotos" onClick={() => this.toggleNav()}>
              Foto's
            </Link>
            <Link to="/contact" onClick={() => this.toggleNav()}>
              Contact
            </Link>
          </div>
        </div>
      </nav>
    );
  }
}
